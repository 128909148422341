<template>
  <div class="my-register-page">
    <div class="my-container">
      <b-row class="mb-2">
        <b-col sm="12" lg="8">
          <h1>{{ $t("inspectionbook") }}</h1>
        </b-col>
        <b-col class="mt-4" style="text-align:right">
          <b-button
            style="width:100px"
            @click="$router.go(-1)"
            variant="outline-danger"
          >
            {{ $t("back") }}
          </b-button>
        </b-col>
      </b-row>

      <b-table-simple bordered>
        <b-thead>
          <b-tr variant="primary">
            <b-th style="vertical-align: middle">
              {{ $t("docNumber") }}
            </b-th>

            <b-th style="vertical-align: middle">
              {{ $t("checkDate") }}
            </b-th>
            <b-th style="vertical-align: middle">
              {{ $t("checkCoverageDate") }}
            </b-th>
            <b-th style="vertical-align: middle">
              {{ $t("orderedOrganization") }}
            </b-th>
            <b-th style="vertical-align: middle">
              {{ $t("inspectionOrganization") }}
            </b-th>
            <b-th style="vertical-align: middle">
              {{ $t("status") }}
            </b-th>
            <b-th style="vertical-align: middle">
              {{ $t("actions") }}
            </b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr v-for="(item, index) in Requests" :key="index">
            <b-td class="text-nowrap" style="vertical-align: middle">
              {{ item.requestDocNumber }}
            </b-td>

            <b-td style="white-space:nowrap">
              {{ item.startDate }} - {{ item.endDate }} ({{
                item.checkDaysNumber
              }}
              {{ $t("day") }} )
            </b-td>
            <b-td style="white-space:nowrap">
              {{ item.checkCoverageStartDate }} -
              {{ item.checkCoverageEndDate }}
            </b-td>
            <b-td>
              {{ item.orderedOrganization }}
            </b-td>
            <b-td>
              {{ item.inspectionOrganization }}
            </b-td>
            <b-td>
              {{ item.status }}
            </b-td>
            <b-td style="vertical-align: middle; text-align: center">
              <b-icon-eye-fill
                v-b-tooltip.hover
                :title="$t('view')"
                @click="Get(item)"
                style="cursor: pointer;margin-right:5px"
              ></b-icon-eye-fill>
              <b-icon
                v-b-tooltip.hover
                :title="$t('send')"
                icon="cursor"
                @click="Send()"
                style="cursor: pointer"
              ></b-icon>
              <b-modal
                hide-footer
                hide-header
                no-close-on-backdrop
                v-model="modal"
              >
                <p>{{ $t("send") }}</p>
                <h5>{{ $t("wantSend") }}</h5>
                <b-row class="mt-2">
                  <b-col class="text-end">
                    <b-button
                      style="margin-right : 5px"
                      variant="outline-danger"
                      @click="BackModal"
                    >
                      {{ $t("no") }}
                    </b-button>
                    <b-button
                      class="ml-2"
                      variant="outline-success"
                      @click="SendFunction(item)"
                    >
                      <b-spinner
                        style="margin-left : 20px"
                        v-if="isSave"
                        small
                      ></b-spinner>
                      {{ $t("yes") }}
                    </b-button>
                  </b-col>
                </b-row>
              </b-modal>
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
      <div>
        <b-pagination
          v-model="filter.page"
          :total-rows="filter.totalRows"
          :per-page="filter.pageSize"
          first-number
          last-number
          @input="Refresh"
          class="mb-0 mt-1 mt-sm-0"
          prev-class="prev-item"
          next-class="next-item"
        >
          <template #prev-text>
            <b-icon icon="chevron-double-left" size="18" />
          </template>
          <template #next-text>
            <b-icon icon="chevron-double-right" size="18" />
          </template>
        </b-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import InspectionBookService from "@/services/inspectionbook.service";
import customSelect from "../../components/elements/customSelect.vue";
import {
  BCard,
  BRow,
  BCol,
  BButton,
  BTableSimple,
  BThead,
  BTbody,
  BTr,
  BTh,
  BTd,
  BIconEyeFill,
  BPagination,
} from "bootstrap-vue";
export default {
  data() {
    return {
      Requests: [],
      modal: false,
      isSave: false,
      filter: {
        page: 1,
        pageSize: 20,
        totalRows: 1,
        options: [
          {
            value: 10,
            text: "10",
          },
          {
            value: 20,
            text: "20",
          },
          {
            value: 50,
            text: "50",
          },
          {
            value: 100,
            text: "100",
          },
        ],
      },
    };
  },
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BThead,
    BTbody,
    BTr,
    BTh,
    BTd,
    BTableSimple,
    BIconEyeFill,
    BPagination,
    customSelect,
  },
  created() {
    this.Refresh();
  },
  methods: {
    Refresh() {
      InspectionBookService.GetList(this.filter)
        .then((res) => {
          this.Requests = res.data.rows;
          this.filter.totalRows = res.data.total;
        })
        .catch((error) => {
          this.Loading = false;
          this.$message(error.response.data);
        });
    },
    GetComplaint(item) {
      this.$router.push({ name: "EditComplaint", params: { id: item.id } });
    },
    Get(item) {
      this.$router.push({
        name: "InspectionBookEdit",
        params: { id: item.id },
      });
    },
    BackModal() {
      this.modal = false;
    },
    Send() {
      this.modal = true;
    },
    SendFunction(item) {
      this.isSave = true;
      InspectionBookService.Send({ id: item.id })
        .then((res) => {
          this.makeToast(this.$t("SuccessSend"), "success");
          this.modal = false;
          this.Refresh();
          this.isSave = false;
        })
        .catch((error) => {
          this.makeToast(error.response.data.errors, "error");
          this.modal = false;
        });
    },
    makeToast(message, type) {
      var a = "";
      if (message.status == 500) {
        a = message.title;
      }
      if (message.status == 400) {
        var errors = Object.values(message.errors);
        var a = errors.map((el, item) => item + 1 + "." + el).join("\n");
      } else {
        a = message;
      }
      this.$toast.open({
        message: a,
        type: type,
        duration: 5000,
        dismissible: true,
      });
    },
    GetIBContractor(item) {
      this.$router.push({
        name: "InspectionBookEdit",
        params: { id: item.id },
      });
    },
  },
};
</script>

<style></style>
